import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { auth } from './app/firebase.js'

//components
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

//pages
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import PublicMessages from './pages/PublicMessages'

//business
import MyBusiness from './pages/business/MyBusiness';
import AddBusiness from './pages/business/AddBusiness';
import EditBusiness from './pages/business/EditBusiness';
import RenewBusiness from './pages/business/RenewBusiness';

//purchases
import MyPurchases from './pages/purchases/MyPurchases';

import PrivateRoute from './components/PrivateRoute';
import PrivateRouteAdmin from './components/PrivateRouteAdmin';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Navigation />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route exact path='/' element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/my-business' element={<MyBusiness />} />
            <Route path='/add-business' element={<AddBusiness />} />
            <Route path='/edit-business/:id' element={<EditBusiness />} />
            <Route path='/renew-business/:id' element={<RenewBusiness />} />
            <Route path='/my-purchases' element={<MyPurchases />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
